.education__data {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    column-gap: 1.5rem;
}

.education__data a {
    color: var(--color-white);
}

.info {
    margin-bottom: 1.5rem;
    display: flex;
}

.info small {
    color: var(--color-light);
}

.info img {
    max-width: 75px;
    object-fit: contain;
    margin-right: 1.5rem;
    filter: grayscale();
    transition: var(--transition);
}

.info:hover img {
    transition: var(--transition);
    filter: none;
}

.education__rounder {
    display: inline-block;
    width: 13px;
    height: 13px;
    background-color: var(--color-primary);
    border-radius: 50%;
    z-index: 1;
    position: relative;
}

.education__line {
    display: block;
    width: 1px;
    height: 100%;
    background-color: var(--color-primary-variant);
    transform: translate(6px, -9px);
}

.education__calendar {
    display: flex;
    align-items: center;
    color: var(--color-light);
}

.education__calendar p {
    margin-left: 1rem;
}

.right_education {
    text-align: end;
    justify-content: flex-end;
}

.right_education div {
    justify-content: flex-end;
}

.right_education img {
    margin-right: 0;
    margin-left: 1.5rem;
}

/* ========================================== MEDIA QUERIES ======================== */
@media screen and (max-width: 600px) {
    .education__data {
        column-gap: 0.5rem;
    }

    .icon {
        display: none;
    }

    .info {
        text-align: center;
        margin-bottom: 4rem;
    }

    .education__calendar {
        justify-content: center;
    }

    .right_education div {
        justify-content: center;
    }
}

.experience__data {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    column-gap: 1.5rem;
}

.experience__data a {
    color: var(--color-white);
}

.info_experience {
    margin-bottom: 1.5rem;
    display: flex;
}

.info_experience small {
    color: var(--color-light);
}

.info_experience img {
    max-width: 75px;
    object-fit: contain;
    margin-right: 1.5rem;
    filter: grayscale();
    transition: var(--transition);
}

#mambo {
    filter: grayscale() brightness(270%);
}

.info_experience:hover img {
    transition: var(--transition);
    filter: none;
}

.info_experience:hover #mambo {
    filter: none;
}

.experience__rounder {
    display: inline-block;
    width: 13px;
    height: 13px;
    background-color: var(--color-primary);
    border-radius: 50%;
    z-index: 1;
    position: relative;
}

.experience__line {
    display: block;
    width: 1px;
    height: 100%;
    background-color: var(--color-primary-variant);
    transform: translate(6px, -9px);
}

.experience__calendar {
    display: flex;
    align-items: center;
    color: var(--color-light);
}

.experience__calendar p {
    margin-left: 1rem;
}

.right_experience {
    text-align: end;
    justify-content: flex-end;
}

.right_experience div {
    justify-content: flex-end;
}

.right_experience img {
    margin-right: 0;
    margin-left: 1.5rem;
}

/* ========================================== MEDIA QUERIES ======================== */
@media screen and (max-width: 600px) {
    .experience__data {
        column-gap: 0.5rem;
    }

    .icon {
        display: none;
    }

    .info_experience {
        text-align: center;
        margin-bottom: 4rem;
        display: flex;
        flex-direction: column-reverse;
    }

    .info_experience img {
        margin: auto;
    }

    .experience__calendar {
        justify-content: center;
    }

    .right_experience {
        display: inline-block;
    }

    .right_experience div {
        justify-content: center;
    }

    .right_experience img {
        margin-right: 0;
        margin-left: 0;
        display: inline-block;
    }
}